import React from 'react';
import Cross from '../../images/icons/customSvg/cross';
import BaseText from '../ui/typography/BaseText';
import PropTypes from 'prop-types';
import { MUI_COLORS } from '../../constants/enum';
import { navigate } from 'gatsby';
import { ENDPOINTS } from '../../service/end-points';

export default function Banner({ onCloseClick, bannerData }) {
  const redirectToLink = () => {
    navigate(bannerData?.link?.url);
  };
  return (
    <div
      className="relative z-1 bg-coral-500 md:h-14 items-center px-5 flex justify-between"
      style={{ backgroundColor: bannerData?.background_color }}
      id="announcement-banner"
    >
      <div></div>
      <div className="flex items-center ">
        <div className="py-[9px] pl-0 md:pl-[29.5px] flex gap gap-1 relative">
          <BaseText
            title={
              <div style={{ color: bannerData?.text_color }}>
                {bannerData?.title?.text}
                <span
                  className={`text-bold cursor-pointer rounded-lg ${
                    bannerData.link_text_background_color ? 'px-2 py-1 text-white ml-4 leading-8' : ' ml-1'
                  }`}
                  style={{ backgroundColor: bannerData.link_text_background_color }}
                  onClick={redirectToLink}
                >
                  {bannerData?.link_text?.text}
                </span>
              </div>
            }
          />
        </div>
      </div>
      <div onClick={() => onCloseClick()} className="cursor-pointer">
        <Cross stroke={MUI_COLORS.WHITE} />
      </div>
    </div>
  );
}

Banner.propTypes = {
  onCloseClick: PropTypes.func,
  bannerData: PropTypes.object
};
Banner.defaultProps = {
  noNeedBottomImage: false,
  bannerData: {
    link: {
      url: ENDPOINTS?.HONG_KONG_LANDING_PAGE
    },
    background_color: MUI_COLORS?.PURPLE,
    link_text: { text: '— Start Now' },
    text_color: MUI_COLORS?.WHITE,
    title: { text: 'Back to Business! Launch Your New Business With 10% Off ' }
  }
};
