exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-hong-kong-vs-singapore-vs-dubai-report-js": () => import("./../../../src/pages/blog/hong-kong-vs-singapore-vs-dubai-report.js" /* webpackChunkName: "component---src-pages-blog-hong-kong-vs-singapore-vs-dubai-report-js" */),
  "component---src-pages-blog-how-long-does-a-swift-transfer-take-js": () => import("./../../../src/pages/blog/how-long-does-a-swift-transfer-take.js" /* webpackChunkName: "component---src-pages-blog-how-long-does-a-swift-transfer-take-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-business-account-js": () => import("./../../../src/pages/business-account.js" /* webpackChunkName: "component---src-pages-business-account-js" */),
  "component---src-pages-business-account-rewards-js": () => import("./../../../src/pages/business-account/rewards.js" /* webpackChunkName: "component---src-pages-business-account-rewards-js" */),
  "component---src-pages-comparisons-js": () => import("./../../../src/pages/comparisons.js" /* webpackChunkName: "component---src-pages-comparisons-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-download-mobile-app-js": () => import("./../../../src/pages/download-mobile-app.js" /* webpackChunkName: "component---src-pages-download-mobile-app-js" */),
  "component---src-pages-enable-phone-mfa-js": () => import("./../../../src/pages/enable-phone-mfa.js" /* webpackChunkName: "component---src-pages-enable-phone-mfa-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forex-js": () => import("./../../../src/pages/forex.js" /* webpackChunkName: "component---src-pages-forex-js" */),
  "component---src-pages-get-rewarded-js": () => import("./../../../src/pages/get-rewarded.js" /* webpackChunkName: "component---src-pages-get-rewarded-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-guides-videos-js": () => import("./../../../src/pages/guides/videos.js" /* webpackChunkName: "component---src-pages-guides-videos-js" */),
  "component---src-pages-hong-kong-company-registration-js": () => import("./../../../src/pages/hong-kong-company-registration.js" /* webpackChunkName: "component---src-pages-hong-kong-company-registration-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integration-xero-js": () => import("./../../../src/pages/integration/xero.js" /* webpackChunkName: "component---src-pages-integration-xero-js" */),
  "component---src-pages-international-and-local-payments-js": () => import("./../../../src/pages/international-and-local-payments.js" /* webpackChunkName: "component---src-pages-international-and-local-payments-js" */),
  "component---src-pages-invoicing-software-js": () => import("./../../../src/pages/invoicing-software.js" /* webpackChunkName: "component---src-pages-invoicing-software-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-mobile-app-js": () => import("./../../../src/pages/mobile-app.js" /* webpackChunkName: "component---src-pages-mobile-app-js" */),
  "component---src-pages-offshore-eligibility-quiz-js": () => import("./../../../src/pages/offshore-eligibility-quiz.js" /* webpackChunkName: "component---src-pages-offshore-eligibility-quiz-js" */),
  "component---src-pages-oops-js": () => import("./../../../src/pages/oops.js" /* webpackChunkName: "component---src-pages-oops-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-refer-a-friend-js": () => import("./../../../src/pages/refer-a-friend.js" /* webpackChunkName: "component---src-pages-refer-a-friend-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-resources-newsletters-js": () => import("./../../../src/pages/resources/newsletters.js" /* webpackChunkName: "component---src-pages-resources-newsletters-js" */),
  "component---src-pages-resources-whitepapers-js": () => import("./../../../src/pages/resources/whitepapers.js" /* webpackChunkName: "component---src-pages-resources-whitepapers-js" */),
  "component---src-pages-reviews-business-account-quiz-js": () => import("./../../../src/pages/reviews/business-account-quiz.js" /* webpackChunkName: "component---src-pages-reviews-business-account-quiz-js" */),
  "component---src-pages-reviews-business-bank-accounts-js": () => import("./../../../src/pages/reviews/business-bank-accounts.js" /* webpackChunkName: "component---src-pages-reviews-business-bank-accounts-js" */),
  "component---src-pages-reviews-company-secretary-hong-kong-index-index-js": () => import("./../../../src/pages/reviews/company-secretary-hong-kong/index/index.js" /* webpackChunkName: "component---src-pages-reviews-company-secretary-hong-kong-index-index-js" */),
  "component---src-pages-reviews-company-secretary-hong-kong-js": () => import("./../../../src/pages/reviews/company-secretary-hong-kong.js" /* webpackChunkName: "component---src-pages-reviews-company-secretary-hong-kong-js" */),
  "component---src-pages-reviews-compsec-score-explained-js": () => import("./../../../src/pages/reviews/compsec-score-explained.js" /* webpackChunkName: "component---src-pages-reviews-compsec-score-explained-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-setup-your-password-js": () => import("./../../../src/pages/setup-your-password.js" /* webpackChunkName: "component---src-pages-setup-your-password-js" */),
  "component---src-pages-signup-failure-js": () => import("./../../../src/pages/signup/failure.js" /* webpackChunkName: "component---src-pages-signup-failure-js" */),
  "component---src-pages-signup-getstarted-js": () => import("./../../../src/pages/signup/getstarted.js" /* webpackChunkName: "component---src-pages-signup-getstarted-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-singapore-company-registration-js": () => import("./../../../src/pages/singapore-company-registration.js" /* webpackChunkName: "component---src-pages-singapore-company-registration-js" */),
  "component---src-pages-style-guide-js": () => import("./../../../src/pages/style-guide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-tools-invoice-generator-js": () => import("./../../../src/pages/tools/invoice-generator.js" /* webpackChunkName: "component---src-pages-tools-invoice-generator-js" */),
  "component---src-pages-tools-paypal-fee-calculator-js": () => import("./../../../src/pages/tools/paypal-fee-calculator.js" /* webpackChunkName: "component---src-pages-tools-paypal-fee-calculator-js" */),
  "component---src-pages-tools-stripe-fee-calculator-js": () => import("./../../../src/pages/tools/stripe-fee-calculator.js" /* webpackChunkName: "component---src-pages-tools-stripe-fee-calculator-js" */),
  "component---src-pages-uk-payment-card-js": () => import("./../../../src/pages/uk/payment-card.js" /* webpackChunkName: "component---src-pages-uk-payment-card-js" */),
  "component---src-pages-under-maintenance-js": () => import("./../../../src/pages/under-maintenance.js" /* webpackChunkName: "component---src-pages-under-maintenance-js" */),
  "component---src-pages-unpublished-preview-js": () => import("./../../../src/pages/unpublishedPreview.js" /* webpackChunkName: "component---src-pages-unpublished-preview-js" */),
  "component---src-pages-verify-mfa-js": () => import("./../../../src/pages/verify-mfa.js" /* webpackChunkName: "component---src-pages-verify-mfa-js" */),
  "component---src-pages-verify-mfa-notification-js": () => import("./../../../src/pages/verify-mfa-notification.js" /* webpackChunkName: "component---src-pages-verify-mfa-notification-js" */),
  "component---src-pages-verify-mfa-otp-js": () => import("./../../../src/pages/verify-mfa-otp.js" /* webpackChunkName: "component---src-pages-verify-mfa-otp-js" */),
  "component---src-pages-what-is-1-volume-fee-js": () => import("./../../../src/pages/what-is-1-volume-fee.js" /* webpackChunkName: "component---src-pages-what-is-1-volume-fee-js" */),
  "component---src-pages-what-is-2-volume-fee-js": () => import("./../../../src/pages/what-is-2-volume-fee.js" /* webpackChunkName: "component---src-pages-what-is-2-volume-fee-js" */),
  "component---src-pages-what-is-3-volume-fee-js": () => import("./../../../src/pages/what-is-3-volume-fee.js" /* webpackChunkName: "component---src-pages-what-is-3-volume-fee-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-country-guide-js": () => import("./../../../src/templates/countryGuide.js" /* webpackChunkName: "component---src-templates-country-guide-js" */),
  "component---src-templates-country-videos-js": () => import("./../../../src/templates/countryVideos.js" /* webpackChunkName: "component---src-templates-country-videos-js" */),
  "component---src-templates-faq-articles-category-template-js": () => import("./../../../src/templates/faqArticlesCategoryTemplate.js" /* webpackChunkName: "component---src-templates-faq-articles-category-template-js" */),
  "component---src-templates-faq-articles-template-js": () => import("./../../../src/templates/faqArticlesTemplate.js" /* webpackChunkName: "component---src-templates-faq-articles-template-js" */),
  "component---src-templates-faq-search-js": () => import("./../../../src/templates/faq-search.js" /* webpackChunkName: "component---src-templates-faq-search-js" */),
  "component---src-templates-glossary-js": () => import("./../../../src/templates/glossary.js" /* webpackChunkName: "component---src-templates-glossary-js" */),
  "component---src-templates-guides-article-js": () => import("./../../../src/templates/guidesArticle.js" /* webpackChunkName: "component---src-templates-guides-article-js" */),
  "component---src-templates-guides-section-js": () => import("./../../../src/templates/guidesSection.js" /* webpackChunkName: "component---src-templates-guides-section-js" */),
  "component---src-templates-guides-video-article-js": () => import("./../../../src/templates/guidesVideoArticle.js" /* webpackChunkName: "component---src-templates-guides-video-article-js" */),
  "component---src-templates-invoice-blog-template-js": () => import("./../../../src/templates/invoiceBlogTemplate.js" /* webpackChunkName: "component---src-templates-invoice-blog-template-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-resource-js": () => import("./../../../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */),
  "component---src-templates-reviews-js": () => import("./../../../src/templates/reviews.js" /* webpackChunkName: "component---src-templates-reviews-js" */),
  "component---src-templates-rewards-js": () => import("./../../../src/templates/rewards.js" /* webpackChunkName: "component---src-templates-rewards-js" */),
  "component---src-templates-search-guide-js": () => import("./../../../src/templates/searchGuide.js" /* webpackChunkName: "component---src-templates-search-guide-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-terms-and-conditions-js": () => import("./../../../src/templates/termsAndConditions.js" /* webpackChunkName: "component---src-templates-terms-and-conditions-js" */),
  "component---src-templates-use-case-js": () => import("./../../../src/templates/useCase.js" /* webpackChunkName: "component---src-templates-use-case-js" */),
  "component---src-templates-user-js": () => import("./../../../src/templates/user.js" /* webpackChunkName: "component---src-templates-user-js" */)
}

